import React, { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";

// @ts-ignore
import * as profile_style from "./css/profile.module.scss";
// @ts-ignore
import * as style from "./css/root.module.css";
// @ts-ignore
import logo from "../assets/images/hot-pies-logo-512.png";
import { request } from "./apiRequest";
import { DataContext } from "./DataContext";

export default function Login() {
    const data = useContext(DataContext);
    const [loginNotify, setLoginNotify] = useState(".");
    const [loginNotifyColor, setLoginNotifyColor] = useState(style.notify);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);

    const validEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    async function login(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        e.preventDefault();

        let res = await request("/login", {
            method: "post",
            body: JSON.stringify({
                email: username,
                password: password
            }),
            headers: {
                "Content-Type": "application/json"
            }
        });

        let text = await res?.text();

        if (res === null || text === undefined) {
            setLoginNotify("Network error occurred. Please try again later");
            setLoginNotifyColor(style.error);
        } else if (res.status === 200) {
            await data.updateData(data);
            setLoggedIn(true);
        } else if (res.status === 400 || res.status === 429) {
            console.error("error " + res.status + " trying to log in: " + text);
            setLoginNotify(text);
            setLoginNotifyColor(style.error);
        } else {
            console.error(res.status + ": " + text);
            setLoginNotify("Something went wrong. Please try again later.");
            setLoginNotifyColor(style.error);
        }
    }

    async function request_password_link() {
        if (username === ""){
            setLoginNotify("Please insert your email");
            setLoginNotifyColor(style.error);
            return;
        }
        if (!validEmail(username)) {
            setLoginNotify("Please insert your email instead of the username");
            setLoginNotifyColor(style.error);
            return;
        }
        
        let res = await request("/resetpassword/", {
            method: "post",
            body: JSON.stringify({email: username})
        });

        if (res == null) {
            setLoginNotify("Network error. Try again later");
            setLoginNotifyColor(style.error);
            return;
        }
        if (res.status === 400) {
            setLoginNotify("Something went wrong. Try again later");
            setLoginNotifyColor(style.error);
        }
    }

    if (loggedIn) {
        return <Navigate to="/" />
    }

    return <>
        <div className={profile_style.holder}>
            <div>
                <div><img className={style.logo} src={logo} width="300px" alt="HotPies Logo"></img></div>
                <form className={profile_style.form} style={{position: "relative"}}>
                    <label htmlFor="username">Username or Email</label>
                    <input type="text" id="username" value={username} onChange={e => { setLoginNotifyColor(style.notify); setUsername(e.target.value) }}></input>

                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" value={password} onChange={e => { setLoginNotifyColor(style.notify); setPassword(e.target.value) }}></input>


                    <p className={`${loginNotifyColor}`} style={{position: "absolute"}}>{loginNotify}</p>

                    <input className={profile_style.big_form_confirmation} type="submit" value="Login" onClick={e => login(e)}></input>
                    <Link to="/newaccount">Create account</Link>
                    <Link to="" style={{float: "right"}} onClick={request_password_link}>Forgot Password?</Link> <br />
                    <Link to="/privacy">Privacy Policy</Link>
                </form>
            </div>
        </div>
    </>
}