import React, { useContext, useEffect, useState } from "react";
//@ts-ignore
import * as style from "./css/root.module.css";
//@ts-ignore
import * as game_style from "./css/game.module.css";
//@ts-ignore
import logo from "../assets/images/hot-pies-logo-512.png";
//@ts-ignore
import Timer from "./Timer";
import { DataContext } from "./DataContext";
import Popup from "./PopupInterface.tsx";
//@ts-ignore
import * as popup_style from "./css/popup.module.css";
import MessageType from "../../types/MessageType.js";
import { requestStartRound } from "./WebSocket.ts";
import { useNavigate } from "react-router-dom";

export default function Game() {
    const data = useContext(DataContext);
    const [is_game_winner_popup_open, set_is_game_winner_popup_open] = useState(false);
    const [is_round_winner_popup_open, set_is_round_winner_popup_open] = useState(false);
    const [game_winner_id, set_game_winner_id] = useState(-1);
    const [round_winner_id, set_round_winner_id] = useState(-1);
    const [button_style, set_button_style] = useState(style.hide_big_button);
    const [button_disabled, set_button_disabled] = useState(true);

    const navigate = useNavigate();

    function gameEventHandler(ev){
        let json;
        try {
            json = JSON.parse(ev.data);
        } catch (err) {
            return;
        }
        if (!json.messageType) {
            return console.log("[WS] no message type sent");
        } else {
            switch (json.messageType) {
                case MessageType.GAME_FINISHED:
                    if (json.winner !== null) {
                        set_game_winner_id(json.winner.userId);
                    }
                    set_is_game_winner_popup_open(true);
                    break;
                case MessageType.ROUND_FINISHED:
                    if (json.winner !== null) {
                        set_round_winner_id(json.winner.userId);
                    }
                    if (data.lobby?.currentRound !== data.lobby?.gs_rounds){
                        set_is_round_winner_popup_open(true);
                    }
                    break;
                case MessageType.ROUND_STARTING:
                    close_round_winner_popup();
                    break;
            }
        }
    }


    useEffect(() => {
        data.ws?.addEventListener("message", gameEventHandler);
        if (data.id === data.lobby?.owner) {
            set_button_style(style.big_button); 
            set_button_disabled(false);
        }

    }, []);

    const open_game_winner_popup = () => {
        set_is_game_winner_popup_open(true);
    }

    const close_game_winner_popup = () => {
        set_is_game_winner_popup_open(false);
    }

    const game_winner_popup_content =
        <div className={popup_style.popup_content}>
            <h2>Game Results</h2>
            <p>{game_winner_id === -1 ? "That was a tie!" : "And the winner is " + data.lobby?.players.find(p => p.playerId === game_winner_id)?.username}</p>
            <p>{game_winner_id === data.id ? "Congratulations!! :)" : "Better luck next time!!"}</p>
            <button className={style.big_button} onClick={() => {close_game_winner_popup(); navigate("/"); window.location.reload();}}>Go back to home page</button>
        </div>

    const open_round_winner_popup = () => {
        set_is_round_winner_popup_open(true);
    }

    const close_round_winner_popup = () => {
        set_is_round_winner_popup_open(false);
    }

    const round_winner_popup_content =
        <div className={popup_style.popup_content}>
            <h2>Round Results</h2>
            <p>{round_winner_id === -1 ? "That was a tie!" : "And the winner is " + data.lobby?.players.find(p => p.playerId === round_winner_id)?.username}</p>
            <p>{round_winner_id === data.id ? "Congratulations!! :)" : "Better luck next time!!"}</p>
            <button className={`${button_style}`} disabled={button_disabled} onClick={() => {close_round_winner_popup(); requestStartRound()}}>Start Next Round</button>
        </div>

    return <div className={style.page_container}>
        <Popup isOpen={is_game_winner_popup_open} onRequestClose={close_game_winner_popup} content={game_winner_popup_content}></Popup>
        <Popup isOpen={is_round_winner_popup_open} onRequestClose={close_round_winner_popup} content={round_winner_popup_content}></Popup>
        <div id={game_style.game}>
            <h3>Game ID</h3>
            <p className={style.num_stats}>{data.lobby?.gameCode}</p>
            <img className={style.logo} src={logo} width="200px" alt="HotPies Logo"></img>
        </div>
        <div id={game_style.grid}>
            <div id={game_style.players}>
                <h3>Players</h3>
                {data.lobby?.players.map(p => <>
                    <h5 id={p.username === data.username ? game_style.current_player : ""}>{p.username}</h5>
                    <div className={game_style.health_score}>
                        <p>Health Score</p>
                        <div className={game_style.progress_bar_holder}>
                            <div className={game_style.progress_bar}>
                                <div className={game_style.progress} style={{width: p.health + "%"}}></div>
                            </div>
                            <p className="health_score_percentage">{p.health}%</p>
                        </div>
                    </div>
                </>)}
            </div>

            <div id={game_style.game_state}>
                <h3>Game State</h3>
                <div className={game_style.state}>
                    <p>Round</p>
                    <p className={style.num_stats}>{data.lobby?.currentRound ? data.lobby.currentRound : 1}/{data.lobby?.gs_rounds}</p>
                </div>

                <div className={game_style.state}>
                    <p>Time left</p>
                    <p className={style.num_stats}><Timer initial_time={data.lobby?.gs_duration ? data.lobby?.gs_duration * 60 : 0} /></p>
                </div>
            </div>

            <div id={game_style.rules}>
                <h3>Rules</h3>
                <div className={game_style.state}>
                    <p>Round time limit</p>
                    <p className={style.num_stats}>{data.lobby?.gs_duration}m</p>
                </div>

                <div className={game_style.state}>
                    <p>Health score reduction per collision</p>
                    <p className={style.num_stats}>{data.lobby?.health_red_per}%</p>
                </div>

                <div className={game_style.state}>
                    <p>Rounds</p>
                    <p className={style.num_stats}>{data.lobby?.gs_rounds}</p>
                </div>
            </div>
        </div>

    </div>
}