.holder{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-top: 0;
    height: 100%;

    > div{
    min-width: 250px;
    max-width: 460px;
    background-color: #424242;
    border-radius: 20px;
    padding: 60px 80px;
    }
}
.form{
    display: block;
    background-color: #424242;
}
.form > a {
    color: #F8DE08;
}
.form > input[type=text], .form > input[type=password], .form > input[type=email]{
    display:block;
    margin-bottom: 5px;
    padding-left: 10px;
    width: calc(100% - 4px - 12px); /* subtract border and padding */
    border-radius: 10px;
} 
.big_form_confirmation{
    margin-top: 65px;
    margin-bottom: 20px;
    width: 100%;
    height: 60px;
    font-size: 20px;
    border-radius: 20px;
    border: none;
    background-color: #EA1C2D;
    color: white;
}
.big_form_confirmation:hover{
    background-color: red;
}
h1{
    color: #F8DE08;
}
#disabled_email{
    color: white;
}
.buttons_holder{
    display: flex;
}