import React, { useEffect, useState } from "react";
//@ts-ignore
import * as table_style from "./css/table.module.css";
//@ts-ignore
import * as style from "./css/root.module.css";
import { request } from "./apiRequest";
import { Leaderboard } from "../../types/types";

export default function Rankings() {
    const [criteriaLabel, setCriteriaLabel] = useState("Win Percentage");
    const [rankingList, setRankingList] = useState([]);
    const [searchRankingList, setSearchRankingList] = useState("");

    useEffect(() => {
        getRanking(criteriaLabel);
    }, [])

    async function getRanking(criteria) {
        let res;
        if (criteria === "Win Percentage") {
            res = await request('/leaderboard/WinPercentage');
        }
        else if (criteria === "Amount of Games Played"){
            res = await request('/leaderboard/Played');
        }
        else {
            res = await request('/leaderboard/Wins');
        }

        if (res == null) {
            return;
        }
        if (res.status === 400 || res.status === 404){
            return;
        }
        setRankingList(await res.json());
    }

    const updateCriteriaLabel = (event) => {
        setCriteriaLabel(event.target.value);
        getRanking(event.target.value);
    };

    return <div className={style.page_container}>
        <h1>Ranking List</h1>

        <div id={table_style.ranking_settings}>
            <div>
                <label htmlFor="ranking_critera">Criteria</label>
                <select id="ranking_critera" onChange={updateCriteriaLabel}>
                    <option value="Win Percentage">Win Percentage</option>
                    <option value="Amount of Games Played">Amount of Games Played</option>
                    <option value="Total Wins">Total Wins</option>
                </select>
            </div>
            <input type="text" placeholder="Search by username" value={searchRankingList} onChange={e => {setSearchRankingList(e.target.value)}}></input>
        </div>

        <table>
            <thead>
                <tr>
                    <td>Username</td>
                    <td>{criteriaLabel}</td>
                </tr>
            </thead>
            <tbody>
                {rankingList
                .filter((row: Leaderboard) => (row.username.toLowerCase().includes(searchRankingList.toLowerCase())))
                .map((row: Leaderboard) => (
                   <tr key={"leaderboard" + row}>
                        <td>{row.username}</td>
                        <td>{criteriaLabel === "Win Percentage" ? (row.amount * 100).toFixed(2) + "%" : row.amount}</td>
                   </tr> 
                ))
                }            
            </tbody>

        </table>
    </div>
}