import config from "../../types/config";

export async function request(endpoint: string, fetchOptions?: RequestInit) {
    try {
        const url = new URL(endpoint, `${config.secure ? "https" : "http"}://${config.serverURL}`);
    
        // Remove credentials include in prod, need it in localhost testing though
        let res = await fetch(url, {...fetchOptions, credentials: "include"});
        return res;
    } catch (e) {
        console.error("network error trying to fetch: " + e);
        return null;
    }
}
