import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
//@ts-ignore
import * as style from "./css/root.module.css";

export default function Privacy () {
    return <div className={style.page_container}>
        <h1>Privacy Policy</h1>
        <p>
            Welcome to <b>Hot Pies</b>! Ensuring the security of your personal data is our top priority. This Data Protection Statement describes 
            the procedures we use to gather, utilize, and protect your personal information when you use our website. You agree to the practices outlined 
            in this statement by using our website.
        </p>

        <ol id={style.privacy_list}>
            <li>
                <h2>Personal Data Collected</h2>
                <p>
                    We may collect the following types of personal information when you use our website:

                    <ul>
                        <li><b>Contact Information:</b> Email address, username and Country</li>
                        <li><b>Usage Information:</b> Information about how you interact with our website, such as your IP address, and browser type.</li>
                    </ul>
                    
                </p>
            </li>

            <li>
                <h2>Use of Your Personal Data</h2>
                <p>
                    We need the information mentioned above so that we can:

                    <ul>
                        <li>
                            Provide our services to you. So you can:
                            <ul>
                                <li>Create an accout</li>
                                <li>Login into your account</li>
                                <li>Reset your password</li>
                                <li>Interact with other users in the system</li>
                            </ul>
                        </li>
                        <li>Enhance your user experience and personalize our website.</li>
                        <li>
                            To communicate with you. We may need to:
                            <ul>
                                <li>Send you request password links to your email</li>
                                <li>Answer your questions and feedback received in our email</li>
                                <li>Send you an email whenever our privacy statement is updated</li>
                            </ul>
                        </li>
                    </ul>
                </p>
            </li>

            <li>
                <h2>Data Sharing</h2>
                <p>
                    Without your permission, we never trade, sell, or otherwise disclose your personal information to unaffiliated third parties. At the moment, we are not affiliated
                    to any third party. If that ever changes, you will be informed via email. These outside partners would help us run our website or manage our operations in exchange 
                    for proper confidentiality and security safeguards.
                </p>
            </li>

            <li>
                <h2>Data Security</h2>
                <p>
                    To safeguard your personal information, we use industry-standard security procedures, mainly against XSS and SQL injections. Please be advised, nevertheless, 
                    that there is no completely safe way to transmit data over the internet or store it electronically.
                </p>
            </li>

            <li>
                <h2>Your Rights</h2>
                <p>
                    You have certain rights regarding your data. These include:
                    <ul>
                        <li>Update your personal data if it is incorrect</li>
                        <li>Receive a copy of your personal data stored in our databse whenever you request it via email</li>
                        <li>Delete your account. When deleting your account all your personal data will be permanently removed from our server</li>
                    </ul>
                </p>
            </li>

            <li>
                <h2>Cookies</h2>
                <p>
                    Our minimal usage of cookies is intended to improve your surfing experience while keeping you safe. For the web application to function properly, 
                    all of these are required.
                </p>
            </li>

            <li>
                <h2>Updates In Our Privacy Policy</h2>                
                <p>
                    This Data Protection Statement may be updated from time to time to reflect modifications to our data practices. Any significant changes will be 
                    communicated to you through email.
                </p>
            </li>

            <li>
                <h2>Contact Us</h2>
                <p>
                    If you have any concern or question about how we manage your data, don't hesitate to contact us. You can send us an email:  
                    <a id={style.email_link} href="mailto:admin@hotpies.cool">admin@hotpies.cool</a>
                </p>
            </li>
        </ol>
        <Link to="/login" id={style.email_link}>Go back to Login</Link>
    </div>

}