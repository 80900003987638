module.exports = {
    PING: 0, // just increment by one for each value, the number is only representative
    CONNECT : 1,
    PICK_CAR : 2,
    JOIN_LOBBY : 3,
    CREATE_LOBBY: 4,
    HIT_MESSAGE: 5,
    CAR_STATE: 6,
    START_GAME: 7,
    PRINT: 8,
    JOINED_LOBBY: 9,
    GAME_STARTING: 10,
    ROUND_STARTING: 11,
    ROUND_FINISHED: 12,
    GAME_FINISHED: 13,
    GET_CARS: 14,
    LOBBY_INFO: 15,
    ERROR: 16,
    GET_MATCH_HISTORY: 17,
    GET_LEADERBOARD: 18,
    START_ROUND: 19,

}