import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";

//@ts-ignore
import * as style from "./css/root.module.css";

//@ts-ignore
import logo from "../assets/images/hot-pies-logo-512.png";
import { DataContext } from "./DataContext";
import { request } from "./apiRequest";

export default function Navbar() {
    const data = useContext(DataContext);
    const [checked, setChecked] = useState(false);
    const [logged_out, set_logged_out] = useState(false);

    async function log_out() {
        let res = await request("/users/logout", { method: "post" });

        if (res == null) {
            return;
        }
        data.ws?.close();
        await data.updateData(data, { ws: undefined });
        set_logged_out(true);
    }

    if (logged_out) {
        return <Navigate to="/login" />;
    }

    return <div id={style.nav}>
        <div id={style.navigation}>
            <Link to="/" className={style.Link}><img className={style.logo} src={logo} height="70px" alt="HotPies Logo"></img></Link>
            <Link to="/" className={style.Link}>Home</Link>
            <Link to="/cars" className={style.Link}>Cars</Link>
            <Link to="/friends" className={style.Link}>Friends</Link>
            <Link to="/ranking" className={style.Link}>Ranking List</Link>
        </div>
        <div id={style.userDropdown}>
            <div id={style.username}>
                <input id={style.userToggle} type="checkbox" checked={checked} onChange={() => setChecked(!checked)}></input>
                <label htmlFor={style.userToggle} className={style.labelToggleNav}>{data.username}</label>

            </div>
            <div id={style.menu} style={checked ? { display: "block" } : { display: "none" }}>
                <Link to="/profile">Edit Profile</Link>
                <p id={style.logout} onClick={log_out}>Log Out</p>
                <Link to="/deleteaccount">Delete Account</Link>
            </div>
        </div>
    </div>
}