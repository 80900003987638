import { useContext } from "react";
import config from "../../types/config";
import MessageType from "../../types/MessageType";
import { Car, Friend } from "../../types/types";
import UserStatus from "../../types/UserStatus";
import { request } from "./apiRequest";
import { WebData } from "./DataContext";

let ws: WebSocket | undefined;

export async function connectToSocket(data: WebData, retryAttempt?: number) {
    if (ws !== undefined && !retryAttempt && !ws.CLOSED) {
        return;
    }

    if (!retryAttempt || retryAttempt < 10) {
        console.log("Attempting to establish server connection...");
    } else if (retryAttempt == 10) {
        console.log("It's taking long to connect. Further messages will be muted but I will keep retrying.");
    }

    const ticket = await request("/wsticket");
    if (ticket === null || ticket.status !== 200) {
        console.error("Error requesting ticket :(");
        if (!document.cookie.includes("SessionID")) {
            return;
        }
        const nextRetryAttempt = (retryAttempt ? retryAttempt : 0) + 1;
        const nextRetryTimeout = Math.round(2.1 ** nextRetryAttempt);
        setTimeout(() => {
            connectToSocket(data, nextRetryAttempt);
        }, Math.min(10000, nextRetryTimeout));
        return;
    }

    ws = new WebSocket(`${config.secure ? "wss" : "ws"}://${config.serverURL}/ws?ticket=${encodeURIComponent(await ticket.text())}`);
    addEventListeners(data, retryAttempt);
}

export function addEventListeners(data: WebData, retryAttempt?: number) {
    if (ws === undefined) {
        return;
    }

    ws.onopen = () => {
        console.log("Server connection established!");
        sendMessage(MessageType.CONNECT);
    };

    ws.onerror = err => {
        if (!retryAttempt || retryAttempt < 10) {
            console.log("[WS] " + (err ? err : "some error occurred"));
        }
    };

    ws.onclose = ev => {
        const nextRetryAttempt = retryAttempt !== undefined ? retryAttempt + 1 : 1;
        const nextRetryTimeout = Math.round(2.1 ** nextRetryAttempt);
        if (!retryAttempt || retryAttempt < 10) console.log(`Connection closed, retrying in ${nextRetryTimeout}ms. Reason: ${ev.reason}`);
        ws = undefined;
        setTimeout(() => {
            connectToSocket(data, nextRetryAttempt);
        }, Math.min(10000, nextRetryTimeout));
    };

    ws.onmessage = ev => {
        let json;
        try {
            json = JSON.parse(ev.data);
        } catch (err) {
            console.log("[WS] could not decode json of message: " + ev.data);
            return;
        }
        console.log(json);
        if (!json.messageType) {
            return console.log("[WS] no message type sent");
        } else {
            let messageType = parseInt(json.messageType);
            switch (messageType) {
                case MessageType.CONNECT:
                    retryAttempt = 0;
                    delete json.messageType;
                    if (json.friends) {
                        json.friends = sortFriends(json.friends);
                    }
                    if (json.cars) {
                        json.cars = sortCars(json.cars);
                    }
                    json.ws = ws;
                    data.updateData(data, json);
                    break;
                case MessageType.LOBBY_INFO:
                    delete json.messageType;
                    data.updateData(data, { lobby: json });
                default:
                    if (!Object.values(MessageType).includes(messageType)) {
                        console.log(`[WS] received a message type (${json.messageType}) which is not supported`);
                    }
            }

        }
    };
}

function sortFriends(friends: Friend[]) {
    return friends.map(f => ({ ...f, statusWeight: f.status === UserStatus.Offline ? 0 : (f.status === UserStatus.Online ? 1 : 2) })).sort((a, b) => b.statusWeight - a.statusWeight);
}

function sortCars(cars: Car[]) {
    return cars.map(c => ({ ...c, statusWeight: c.status === UserStatus.Offline ? 0 : (c.status === UserStatus.InGame ? 1 : 2) })).sort((a, b) => b.statusWeight - a.statusWeight);
}

export function sendMessage(messageType: number, data?: any) {
    if (!(data instanceof Object)) data = {};
    data.messageType = messageType;
    ws?.send(JSON.stringify(data));
}

export function requestCreateLobby(time, reduction, rounds) {
    const message = {
        gs_duration: time,
        gs_health_per_red: reduction,
        gs_rounds: rounds
    }

    sendMessage(MessageType.CREATE_LOBBY, message);
}

export function requestJoinLobby(game_id) {
    const message = {
        gameCode: game_id
    }

    sendMessage(MessageType.JOIN_LOBBY, message);
}

export function requestStartGame() {
    sendMessage(MessageType.START_GAME);
}

export function sendSelectedCar(car_id) {
    const message = {
        carId: car_id
    }
    sendMessage(MessageType.PICK_CAR, message);
}

export function requestStartRound(){
    sendMessage(MessageType.START_ROUND);
}