import React, { useContext, useEffect, useState } from "react";
//@ts-ignore
import * as table_style from "./css/table.module.css";
//@ts-ignore
import * as style from "./css/root.module.css";
//@ts-ignore
import * as popup_style from "./css/popup.module.css";
//@ts-ignore
import Popup from "./PopupInterface.tsx";
import { request } from "./apiRequest";
import { Friend } from "../../types/types";
import { DataContext } from "./DataContext.ts";
import UserStatus from "../../types/UserStatus.js";

export default function Cars() {
    const data = useContext(DataContext);
    const [search_playable_cars, set_search_playable_cars] = useState("");
    const [delete_car_id, set_delete_car_id] = useState(-1);
    const [manage_car_id, set_manage_car_id] = useState(-1);

    //definition of add car popup
    const [is_add_car_popup_open, set_is_add_car_popup_open] = useState(false);
    const [car_name, set_car_name] = useState("");
    const [serial_number, set_serial_number] = useState("");
    const [add_car_message, set_add_car_message] = useState(".");
    const [add_car_message_style, set_add_car_message_style] = useState(style.notify);

    const open_add_car_popup = () => {
        set_is_add_car_popup_open(true);
    };

    const close_add_car_popup = () => {
        set_is_add_car_popup_open(false);
    };

    async function add_car() {
        if (serial_number === "") {
            set_add_car_message("Please insert serial number");
            set_add_car_message_style(style.error);
            return;
        }
        if (car_name === "") {
            set_add_car_message("Please insert name");
            set_add_car_message_style(style.error);
            return;
        }

        let res = await request("/cars/create", {
            method: "post",
            body: JSON.stringify({
                name: car_name,
                serial_code: serial_number
            }),
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res == null) {
            set_add_car_message("Network error. Please try again later");
            set_add_car_message_style(style.error);
            return;
        }
        if (res.status === 404) {
            set_add_car_message("Error 404 - Not Found");
            set_add_car_message_style(style.error);
            return;
        }
        if (res.status === 400) {
            console.log(res);
            set_add_car_message("Something went wrong. Please try again later");
            set_add_car_message_style(style.error);
            return;
        }
        close_add_car_popup();
    }

    const add_car_popup_content =
        <div className={popup_style.popup_content}>
            <h2>Add new car</h2>
            <form>
                <label htmlFor="serial_number">Serial number</label>
                <input type="text" id="serial_number" value={serial_number} onChange={e => { set_serial_number(e.target.value) }}></input>

                <label htmlFor="car_name">Car name</label>
                <input type="text" id="car_name" value={car_name} onChange={e => { set_car_name(e.target.value) }}></input>
            </form>

            <p className={`${add_car_message_style}`}>{add_car_message}</p>

            <div className={popup_style.buttons_holder}>
                <input className={style.cancel_button} type="button" value="Cancel" onClick={close_add_car_popup}></input>
                <input className={style.confirm_button} type="button" value="Confirm" onClick={add_car}></input>
            </div>
        </div>

    //define add authorized user popup
    const [is_add_authorized_user_popup_open, set_is_add_authorized_user_popup_open] = useState(false);
    const [not_authorized_friends, set_not_authorized_friends] = useState([]);
    const [add_authorized_user_error_message, set_add_authorized_user_error_message] = useState(".");
    const [add_authorized_user_error_style, set_add_authorized_user_error_style] = useState(style.notify);
    const [search_not_authorized_users, set_search_not_authorized_users] = useState("");

    const open_add_authorized_user_popup = () => {
        get_friends_without_access();
        set_is_add_authorized_user_popup_open(true);
    }

    const close_add_authorized_user_popup = () => {
        set_not_authorized_friends([]);
        get_friends_without_access();
        set_is_add_authorized_user_popup_open(false);
    }

    async function get_friends_without_access() {
        console.log("fetching unauthorized friends");
        let res = await request("/cars/car/" + manage_car_id + "/notAddedFriends");

        if (res == null) {
            set_add_authorized_user_error_message("Network error. Try again later");
            set_add_authorized_user_error_style(style.error);
            return;
        }

        if (res.status === 400) {
            set_add_authorized_user_error_message("Something went wrong. Try again later");
            set_add_authorized_user_error_style(style.error);
            return;
        }

        if (res.status === 404) {
            set_add_authorized_user_error_message("Error 404. Not found");
            set_add_authorized_user_error_style(style.error);
            return;
        }

        set_not_authorized_friends(await res.json());
    }

    async function add_authorized_user(user_id) {
        let res = await request("/cars/car/" + manage_car_id + "/addUser/" + user_id, { method: "put" });

        if (res == null) {
            set_add_authorized_user_error_message("Network error. Try again later");
            set_add_authorized_user_error_style(style.error);
            return;
        }
        if (res.status === 400) {
            set_add_authorized_user_error_message("Something went wrong. Try again later");
            set_add_authorized_user_error_style(style.error);
            return;
        }
        if (res.status === 404) {
            set_add_authorized_user_error_message("Error 404. Not found");
            set_add_authorized_user_error_style(style.error);
            return;
        }
        get_friends_without_access();
    }

    const add_authorized_user_popup_content =
        <div className={popup_style.popup_content}>
            <h2>Add Authorized Users</h2>
            <input type="text" placeholder="Search by username" value={search_not_authorized_users} onChange={e => { set_search_not_authorized_users(e.target.value) }}></input>
            <table>
                <thead>
                    <tr>
                        <td>User</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {not_authorized_friends
                        .filter((friend: Friend) => (friend.username.toLowerCase().includes(search_not_authorized_users.toLowerCase()) || search_not_authorized_users === ""))
                        .map((friend: Friend) => (
                            <tr key={friend.id}>
                                <td>{friend.username}</td>
                                <td>
                                    <button className={style.small_button} onClick={() => { add_authorized_user(friend.id) }}>Add</button>
                                </td>
                            </tr>
                        ))

                    }
                </tbody>
            </table>
            <p className={`${add_authorized_user_error_style}`}>{add_authorized_user_error_message}</p>
            <button id={popup_style.large_confirm} className={style.small_button} onClick={close_add_authorized_user_popup}>Confirm</button>
        </div>


    //definition of manage access
    const [is_manage_access_popup_open, set_is_manage_access_popup_open] = useState(false);
    const [access_users, set_access_users] = useState([]);
    const [manage_access_error_message, set_manage_access_error_message] = useState(".");
    const [manage_access_error_style, set_manage_access_error_style] = useState(style.notify);
    const [search_access_users, set_search_access_users] = useState("");

    async function get_manage_access_users(car_id) {
        console.log(car_id);
        let res = await request("/cars/car/" + car_id + "/users");

        if (res == null) {
            set_manage_access_error_message("Network error. Try again later");
            set_manage_access_error_style(style.error);
            return;
        }
        if (res.status === 404) {
            set_manage_access_error_message("Error 404. Not Found");
            set_manage_access_error_style(style.error);
            return;
        }
        if (res.status === 400) {
            set_manage_access_error_message("Something went wrong. Try again later");
            set_manage_access_error_style(style.error);
            return;
        }
        set_access_users(await res.json());
    }


    const open_manage_access_popup = (car_id, carname) => {
        set_car_name(carname);
        set_manage_car_id(car_id);
        get_manage_access_users(car_id);
        set_is_manage_access_popup_open(true);
    };

    const close_manage_access_popup = () => {
        set_access_users([]);
        set_car_name("");
        set_is_manage_access_popup_open(false);
    };

    async function remove_user(user_id) {
        let res = await request("/cars/car/" + manage_car_id + "/removeUser/" + user_id, { method: "delete" });

        if (res == null) {
            set_manage_access_error_message("Network error. Try again later");
            set_manage_access_error_style(style.error);
            return;
        }
        if (res.status === 400) {
            set_manage_access_error_message("Something went wrong. Try again later");
            set_manage_access_error_style(style.error);
            return;
        }
        if (res.status === 404) {
            set_manage_access_error_message("Error 404. Not Found");
            set_manage_access_error_style(style.error);
            return;
        }
        get_manage_access_users(manage_car_id);
    }

    const manage_access_popup_content =
        <div className={popup_style.popup_content}>
            <div className={style.page_header_holder}>
                <h3>Manage access to {car_name}</h3>
                <button className={style.small_button} onClick={open_add_authorized_user_popup}>Add User</button>
            </div>
            <input type="text" placeholder="Search by username" value={search_access_users} onChange={e => { set_search_access_users(e.target.value) }}></input>

            <table>
                <thead>
                    <tr>
                        <td>User</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {access_users
                        .filter((user: Friend) => (user.id !== data.id && (user.username.toLowerCase().includes(search_access_users.toLowerCase()) || search_access_users === "")))
                        .map((user: Friend) => (
                            <tr key={user.id}>
                                <td>{user.username}</td>
                                <td>
                                    <button className={style.small_button} onClick={() => { remove_user(user.id) }}>Remove</button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <p className={`${manage_access_error_style}`}>{manage_access_error_message}</p>
            <button id={popup_style.large_confirm} className={style.small_button} onClick={close_manage_access_popup}>Confirm</button>
        </div>

    //define delete car popup
    const [is_delete_car_popup_open, set_is_delete_car_popup_open] = useState(false);
    const [delete_car_error_message, set_delete_car_error_message] = useState(".");
    const [delete_car_error_style, set_delete_car_error_style] = useState(style.notify)

    const open_delete_car_popup = (car_id) => {
        set_delete_car_id(car_id);
        set_is_delete_car_popup_open(true);
    }

    const close_delete_car_popup = () => {
        set_is_delete_car_popup_open(false);
        set_delete_car_id(-1);
    }

    async function delete_car() {
        let res = await request("/cars/car/" + delete_car_id, { method: "delete" });

        if (res == null) {
            set_delete_car_error_message("Network error. Try again later");
            set_delete_car_error_style(style.error);
            return;
        }
        if (res.status === 404) {
            set_delete_car_error_message("Error 404. Not Found");
            set_delete_car_error_style(style.error);
            return;
        }
        if (res.status === 400) {
            set_delete_car_error_message("Something went wrong. Try again later");
            set_delete_car_error_style(style.error);
            return;
        }

        close_delete_car_popup();
    }

    const delete_car_popup_content =
        <div className={popup_style.popup_content}>
            <p className={popup_style.confirm_message}>This action cannot be undone. Are you sure you want to proceed?</p>
            <p className={`${delete_car_error_style}`}>{delete_car_error_message}</p>
            <div className={popup_style.buttons_holder}>
                <input className={style.cancel_button} type="button" value="Cancel" onClick={close_delete_car_popup}></input>
                <input className={style.confirm_button} type="button" value="Confirm" onClick={delete_car}></input>
            </div>
        </div>



    return <div className={style.page_container}>
        <div className={style.page_header_holder}>
            <h1>Your Cars</h1>
            <button onClick={open_add_car_popup} className={style.big_button}>Add Car</button>
        </div>

        <input type="text" placeholder="Search by car name, connection or owner" value={search_playable_cars} onChange={e => { set_search_playable_cars(e.target.value) }}></input>

        <Popup isOpen={is_delete_car_popup_open} onRequestClose={close_delete_car_popup} content={delete_car_popup_content}></Popup>
        <Popup isOpen={is_add_car_popup_open} onRequestClose={close_add_car_popup} content={add_car_popup_content}></Popup>
        <Popup isOpen={is_manage_access_popup_open} onRequestClose={close_manage_access_popup} content={manage_access_popup_content}></Popup>
        <Popup isOpen={is_add_authorized_user_popup_open} onRequestClose={close_add_authorized_user_popup} content={add_authorized_user_popup_content}></Popup>

        <table>
            <thead>
                <tr>
                    <td>Car name</td>
                    <td>Owner</td>
                    <td>Connection</td>
                    <td>Actions</td>
                </tr>
            </thead>
            <tbody>
                {data.cars
                    .filter((c) => (c.name.toLowerCase().includes(search_playable_cars.toLowerCase()) || c.ownername.toLowerCase().includes(search_playable_cars.toLowerCase()) 
                                    || c.status.toLowerCase().includes(search_playable_cars.toLowerCase()) || search_playable_cars === "") || ("you".includes(search_playable_cars.toLowerCase()) && c.ownername === data.username))
                    .map((c) => (
                        <tr key={c.id}>
                            <td>{c.name}</td>
                            <td>
                                {c.ownerid === data.id ? (
                                    "You"
                                ) : (
                                    c.ownername
                                )}
                            </td>
                            <td>
                                <div className={style.connection_holder}>
                                    <p className={c.status === UserStatus.Online ? style.online_circle : (c.status === UserStatus.Offline ? style.offline_circle : style.idle_circle)}>⬤</p>
                                    <p>{c.status}</p>
                                </div>
                            </td>
                            <td>
                                {c.ownerid === data.id ? (
                                    <>
                                        <button className={style.small_button} id={popup_style.manage_access} onClick={() => { open_manage_access_popup(c.id, c.name) }}>
                                            Manage Access
                                        </button>
                                        <button className={style.small_button} onClick={() => { open_delete_car_popup(c.id) }}>
                                            Delete
                                        </button>
                                    </>
                                ) : null}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    </div>
}