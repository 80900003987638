import React from "react";
import Modal from "reactjs-popup";
//@ts-ignore
import * as popup_style from "./css/popup.module.css";

interface PopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
  content: React.ReactNode;
}

const PopupInterface: React.FC<PopupProps> = ({ isOpen, onRequestClose, content }) => {
  return (
    <Modal open={isOpen} onClose={onRequestClose} closeOnDocumentClick={false}>
      <div className={popup_style.popup_holder}>
        {content}
      </div>
    </Modal>
  );
};

export default PopupInterface;