import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Outlet, Route, Routes, redirect } from "react-router-dom";
import React, { useEffect, useState } from "react";
//import { WebSocket } from "ws";
import { DataContext, emptyData, WebData } from "./DataContext.js";

// @ts-ignore
import * as style from "./css/root.module.css";

import Navbar from "./Navbar";
import Login from "./Login";
import Home from "./Home";
import Cars from "./Cars";
import Friends from "./Friends";
import Ranking from "./Ranking";
import Profile from "./Profile";
import Account from "./Account";
import Game from "./Game";
import DeleteProfile from "./DeleteProfile";
import Privacy from "./Privacy";
import { addEventListeners, connectToSocket } from "./WebSocket.js";
import Password from "./Password.js";

function Root() {
    const [data, setData] = useState<WebData>({ ...emptyData, updateData });

    async function updateData(currentData: WebData, newData?: Object | "logout") {
        if (newData) {
            if (newData === "logout") {
                updateData({ ...emptyData, updateData });
            } else {
                setData({ ...currentData, ...newData });
                return;
            }
        }

        if (document.cookie.includes("SessionID")) {
            if (!currentData.ws) {
                await connectToSocket(currentData);
            }
        }
    }

    useEffect(() => {
        updateData(data);
    }, [])

    useEffect(() => {
        addEventListeners(data);
    }, [data])

    return <BrowserRouter>
        <div id={style.view}>
            <DataContext.Provider value={data}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/password" element={<Password />} />
                    <Route path="/newaccount" element={<Account />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/game" element={<PrivateRoute><Game /></PrivateRoute>} />
                    <Route element={
                        <>
                            <Navbar />
                            <Outlet />
                        </>
                    }>
                        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
                        <Route path="/cars" element={<PrivateRoute><Cars /></PrivateRoute>} />
                        <Route path="/friends" element={<PrivateRoute><Friends /></PrivateRoute>} />
                        <Route path="/ranking" element={<PrivateRoute><Ranking /></PrivateRoute>} />
                        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                        <Route path="/deleteaccount" element={<PrivateRoute><DeleteProfile /></PrivateRoute>} />
                    </Route>
                </Routes>
            </DataContext.Provider>
        </div>
    </BrowserRouter>
}

function PrivateRoute(props: React.PropsWithChildren) {
    const hasSessionToken = document.cookie.includes("SessionID");
    if (!hasSessionToken) {
        return <Navigate to="/login" />;
    }

    return props.children;
}

createRoot(document.getElementById("root")).render(<Root />);
