.popup_holder{
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width:100%;
    height:100%;
}

.popup_content{
    background-color: #424242;
    border-radius: 20px;
    padding: 60px 80px;
}

form > input[type=text], 
form > input[type=password],
form > input[type=email]{
    display:block;
    margin-bottom: 5px;
    padding-left: 10px;
    width: 100%;
    border-radius: 10px;
}

.back_arrow{
    font-size: 36px;
    cursor: pointer;
    margin-right: 20px;
}

h1,
h2,
h3,
h4{
    color: #F8DE08;
}

.buttons_holder{
    display: flex;
}

.search_bar{
    width: 400px;
}

#large_confirm{
    margin-top: 30px;
    width: 100%;
}

.header{
    display: flex;
    align-items: center;
}

.buttons_holder{
    display: flex;
}

#manage_access{
    margin-right: 10px;
}

.confirm_message{
    margin-bottom: 20px;
}