html,
body {
    height: 100vh;
    margin: 0;
}

body {
    background-color: #121212;
    font-family: "Segoe UI", Verdana, sans-serif;
    color: white;
}

#view {
    height: 100%;
}

h1 {
    margin-top: 0;
    margin-bottom: 0;
}

#nav {
    width: 100%;
    height: 70px;
    line-height: 50px;
    display: flex;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #424242;
    color: #F8DE08;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
}

#userToggle {
    display: none;
}

.labelToggleNav {
    display: block;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.25s ease-out;
}

.labelToggleNav:hover {
    color: #EA1C2D;
}

.labelToggleNav::before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.7rem;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    transform: translateY(-2px);
    transition: transform .2s ease-out;
}

#userToggle:checked+.labelToggleNav::before {
    transform: rotate(90deg) translateX(-3px);
}

#navigation {
    min-height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #424242;
    float: left;
    column-gap: 40px;
}

.Link {
    border-top: 0;
    font-size: 20px;
    font-weight: 600;
    color: #F8DE08;
    text-decoration: none;
}

.Link:has(img) {
    line-height: 0;
}

#logo {
    width: 150px;
    vertical-align: text-bottom;
}

.Link:hover {
    color: #EA1C2D;
}

#userDropdown {
    min-height: 50px;
    min-width: 160px;
    position: relative;
    background-color: #424242;
    float: right;
}

#username {
    border-top: 0;
    font-size: 20px;
    padding: 10px 20px;
    text-decoration: none;
}

#menu {
    z-index: 100;
    display: none;
    background-color: #212121;
    position: absolute;
    padding: 0 20px;
    width: calc(100% - 40px); /* 100% - 20px * 2 for padding */
}

#menu>a {
    display: block;
    text-decoration: none;
    color: #F8DE08;
}

#menu>a:hover {
    color: #EA1C2D;
}

.num_stats {
    font-size: 50px;
}

.big_button {
    width: 300px;
    height: 60px;
    font-size: 20px;
    border-radius: 20px;
    border: none;
    background-color: #EA1C2D;
    color: white;
}

.big_button:hover,
.small_button:hover {
    background-color: red;
    cursor: pointer;
}

.small_button {
    width: 150px;
    height: 30px;
    font-size: 13px;
    border-radius: 20px;
    border: none;
    background-color: #EA1C2D;
    color: white;
}

.cancel_button,
.confirm_button {
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
}

.cancel_button {
    background-color: #F8DE08;
}

.cancel_button:hover {
    background-color: yellow;
}

.confirm_button {
    background-color: #EA1C2D;
    color: white;
}

.confirm_button:hover {
    background-color: red;
}

.idle_circle {
    color: yellow;
    margin-right: 10px;
}

.online_circle {
    color: green;
    margin-right: 10px;
}

.offline_circle {
    color: #353535;
    margin-right: 10px;
}

.connection_holder {
    display: flex;
    align-content: center;
}

.page_header_holder {
    display: flex;
    justify-content: space-between;
}

.page_container {
    margin: 40px 80px;
    padding-bottom: 50px;
}

.notify{
    color: transparent;
    margin-bottom: 10px;
}

.error {
    color: #ff8282;
    margin-bottom: 10px;
    font-weight: 500;
}

.success {
    color: green;
    margin-bottom: 10px;
}

.waiting {
    color: #F8DE08;
    margin-bottom: 10px;
}

.hide_big_button {
    background-color: transparent;
    width: 300px;
    height: 60px;
    font-size: 20px;
    border-radius: 20px;
    border: none;
    color: transparent;
}

#logout {
    cursor: pointer;
}

#logout:hover{
    color:#EA1C2D;
}

#email_link {
    color:#EA1C2D;   
}

#privacy_list > li{
    color:#F8DE08;
}

#privacy_list > li > p{
    color: white;
}