import React, { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
//@ts-ignore
import * as profile_style from "./css/profile.module.scss";
//@ts-ignore
import * as style from "./css/root.module.css";
//@ts-ignore
import logo from "../assets/images/hot-pies-logo-512.png";
import Countries from "./Countries";
import { request } from "./apiRequest";
import { DataContext } from "./DataContext";

export default function Account() {
    const data = useContext(DataContext);
    const [notify_message, set_notify_message] = useState(".")
    const [notify_message_color, set_notify_message_color] = useState(style.notify);

    const [username, set_username] = useState("");
    const [email, set_email] = useState("");
    const [password, set_password] = useState("");
    const [confirm_password, set_confirm_password] = useState("");
    const [country, set_country] = useState("");

    const [logged_in, set_logged_in] = useState(false);

    const validEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const hide_notify_message = () => {
        set_notify_message(".");
        set_notify_message_color(style.notify);
    };

    const update_username = (event) => {
        hide_notify_message();
        set_username(event.target.value);
    };

    const update_email = (event) => {
        hide_notify_message();
        set_email(event.target.value);
    };

    const update_password = (event) => {
        hide_notify_message();
        set_password(event.target.value);
    };

    const update_confirm_password = (event) => {
        hide_notify_message();
        set_confirm_password(event.target.value);
    };

    async function create_account() {
        let res = await request("/createUser", {
            method: "post",
            body: JSON.stringify({
                email: email,
                password: password,
                username: username,
                country: country
            }),
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res == null) {
            set_notify_message("Network error. Please try again later");
            set_notify_message_color(style.error);
        } else if (res.status === 500) {
            set_notify_message("Internal sever error. Please try again later");
            set_notify_message_color(style.error);
        } else if (res.status === 200) {
            await data.updateData(data);
            set_logged_in(true)
        } else if (res.status === 400 || res.status === 429) {
            set_notify_message(await res.text());
            set_notify_message_color(style.error);
        }
    };

    const handle_create_account = () => {
        if (username === "") {
            set_notify_message("Please insert username");
            set_notify_message_color(style.error);
            return;
        }
        if (email === "") {
            set_notify_message("Please insert email");
            set_notify_message_color(style.error);
            return;
        }
        if (!validEmail(email)) {
            set_notify_message("This email is not valid");
            set_notify_message_color(style.error);
            return;
        }
        if (country === "") {
            set_notify_message("Please select country");
            set_notify_message_color(style.error);
            return;
        }
        if (password === "") {
            set_notify_message("Please insert password");
            set_notify_message_color(style.error);
            return;
        }
        if (confirm_password === "") {
            set_notify_message("Please confirm your password");
            set_notify_message_color(style.error);
            return;
        }
        if (confirm_password !== password) {
            set_notify_message("Passwords don't match. Please try again");
            set_notify_message_color(style.error);
            return;
        }
        create_account();
    };

    if (logged_in) {
        return <Navigate to="/" />
    }

    return <>
        <div className={profile_style.holder}>
            <div>
                <div><img className={style.logo} src={logo} width="100%" alt="HotPies Logo"></img></div>
                <form className={profile_style.form}>
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" onInput={update_email}></input>

                    <label htmlFor="username">Username</label>
                    <input type="text" id="username" onInput={update_username}></input>

                    <Countries updateFunction={set_country} />

                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" onInput={update_password}></input>

                    <label htmlFor="confirm_password">Repeat password</label>
                    <input type="password" id="confirm_password" onInput={update_confirm_password}></input>

                    <p className={`${notify_message_color}`}>{notify_message}</p>

                    <input className={profile_style.big_form_confirmation} onClick={handle_create_account} type="button" value="Create Account"></input>
                    <Link to="/login">Already with us? Login</Link>
                </form>
            </div>
        </div>
    </>
}