.car_component > input {
    display: none;
}

.car_component {
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 10px;
    
}

.car_component > label {
    display: block;
    padding: 10px;
    border: 1px solid #8c8c8c;
    border-radius: 10px;
}

.car_component > input:checked + label {
    color: black;
    background-color: #F8DE08;
}