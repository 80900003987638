import React, { useState, useEffect } from 'react';

interface TimerProps {
    initial_time: number;
}

const Timer: React.FC<TimerProps> = ({ initial_time }) => {
    const [time, set_time] = useState(initial_time);
    const [is_running, set_is_running] = useState(true);
  
    useEffect(() => {
        let timer: number;
    
        if (is_running && time > 0) {
            timer = setInterval(() => {
            set_time((prev_time) => prev_time - 1);
            }, 1000);
        }
    
        return () => {
            clearInterval(timer);
        };
    }, [is_running, time]);
  
    const start_timer = () => {
        set_is_running(true);
    };
  
    const stop_timer = () => {
        set_is_running(false);
    };
  
    const reset_timer = () => {
        set_time(initial_time);
        set_is_running(false);
    };
  
    const format_time = (time: number) => {
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
    
        return `${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
    };

    return (
        <div className="timer">
            <span>{format_time(time)}</span>
        </div>
    );
}

export default Timer;