#stats_holder{
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    margin: 50px 0;
}
.stats{
    border-radius: 10px;
    border: 1px solid #8C8C8C;
    width: 30%;
    padding-bottom: 30px;
    position: relative;
}
.stats > h2, .stats > a, .stats > div{
    padding-left: 20px;
}
.stats > a{
    text-decoration: none;
    color: #C1C1C1;
    position: absolute;
    bottom: 10;
}
#buttons_holder{
    display: flex;
    justify-content: space-around;
    margin: 50px 0;
}
.element_holder{
    display: block;
    margin-bottom: 20px;
}
p{
    margin-top: 0;
    margin-bottom: 0;
}