import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//@ts-ignore
import * as profile_style from "./css/profile.module.scss";
//@ts-ignore
import * as style from "./css/root.module.css";
import { DataContext } from "./DataContext";
import Countries from "./Countries";
import { request } from "./apiRequest";

export default function Profile() {
    const data = useContext(DataContext);
    const [email, set_email] = useState(data.email);
    const [username, set_username] = useState(data.username);
    const navigate = useNavigate();
    const [country, set_country] = useState(data.country);
    const [notify_message, set_notify_message] = useState(".");
    const [notify_class, set_notify_class] = useState(style.notify);

    useEffect(() => {
        set_email(data.email);
        set_username(data.username);
        set_country(data.country);
    }, [data.email, data.username, data.country]);

    const go_back = () => {
        navigate(-1);
    };

    async function edit_profile() {
        let res = await request("/users/me", {
            method: "PATCH",
            body: JSON.stringify({
                username: username,
                country: country
            }),
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res == null) {
            set_notify_message("Network error. Try again later");
            set_notify_class(style.error);
            return;
        }
        if (res.status !== 200) {
            set_notify_message("Something went wrong. Try again later");
            set_notify_class(style.error);
            return;
        }

        await data.updateData(data, { email, username, country });

        go_back();
    }

    async function request_password_link() {
        let res = await request("/resetpassword", {
            method: "post",
            body: JSON.stringify({email}),
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res == null) {
            set_notify_message("Network error. Try again later");
            set_notify_class(style.error);
            return;
        }
        if (res.status === 400) {
            set_notify_message("Something went wrong. Try again later");
            set_notify_class(style.error);
        }
    }

    return <>
        <div className={profile_style.holder}>
            <div>
                <h1>Edit Profile</h1>
                <form className={profile_style.form}>
                    <label htmlFor={profile_style.disabled_email}>Email</label>
                    <input type="email" id={profile_style.disabled_email} value={email} disabled></input>

                    <label htmlFor="username">Username</label>
                    <input type="text" id="username" value={username} onChange={(e) => { set_username(e.target.value); set_notify_message("."); set_notify_class(style.notify) }}></input>

                    <Countries country={country} updateFunction={set_country} onClick={() => { set_notify_message("."); set_notify_class(style.notify) }} />

                    <Link to="" onClick={request_password_link}>Reset Password</Link>

                    <p className={`${notify_class}`}>{notify_message}</p>

                    <div className={profile_style.buttons_holder}>
                        <input className={style.cancel_button} type="button" value="Cancel" onClick={go_back}></input>
                        <input className={style.confirm_button} type="button" value="Confirm" onClick={edit_profile}></input>
                    </div>
                </form>
            </div>
        </div>
    </>
}