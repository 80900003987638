#holder{
    background-color: #424242;
    border-radius: 20px;
    padding: 60px 60px;
}

#options_div{
    background-color: #424242;    
    border: 1px solid #8C8C8C;
    border-radius: 10px;
}

#options_div > fieldset > input[type=radio]{
    display: none;
}

#options_div > fieldset{
    display: flex;
    justify-content: space-around;
    border-radius:10px;
    border: none;
}

#options_div > fieldset > label{
    border: 1px solid #8C8C8C;
    padding: 6px; 
    border-radius:10px;
    flex:1;
    text-align: center;
}

#options_div > fieldset > input[type=radio]:checked + label {
    background-color: yellow;
    color: black;
}

#header{
    display: flex;
    align-items: center;
}
