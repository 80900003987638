import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//@ts-ignore
import * as home_style from "./css/home.module.css";
//@ts-ignore
import * as style from "./css/root.module.css";
//@ts-ignore
import * as popup_style from "./css/popup.module.css";
//@ts-ignore
import * as rules_style from "./css/rules.module.css";
//@ts-ignore
import * as select_car_style from "./css/select_car.module.css";
//@ts-ignore
import * as lobby_style from "./css/lobby.module.css";
//@ts-ignore
import Popup from "./PopupInterface.tsx";
import { DataContext } from "./DataContext.ts";
import UserStatus from "../../types/UserStatus.js";
import { requestCreateLobby, requestJoinLobby, requestStartGame, sendSelectedCar } from "./WebSocket.ts";
import MessageType from "../../types/MessageType.js";
import { request } from "./apiRequest.ts";

export default function Home() {
    const data = useContext(DataContext);
    const navigate = useNavigate();
    const [select_car_notify, set_select_car_notify] = useState(".");
    const [select_car_notify_color, set_select_car_notify_color] = useState(style.notify);
    const [waiting_notify_color, set_waiting_notify_color] = useState(style.notify);
    const [display_start_game_button, set_display_start_game_button] = useState(style.hide_big_button);
    const [set_rules_notify, set_set_rules_notify] = useState(".")
    const [set_rules_notify_color, set_set_rules_notify_color] = useState(style.notify);
    const [set_lobby_notify_color, set_set_lobby_notify_color] = useState(style.notify);

    const [lobby_mode, set_lobby_mode] = useState("");
    const [is_start_game_disabled, set_is_start_game_disabled] = useState(true);
    const [waitForLobbyInfo, setWaitForLobbyInfo] = useState(true);

    const [selected_car_id, set_selected_car_id] = useState(-1);

    const hide_select_car_notify = () => {
        set_select_car_notify(".");
        set_select_car_notify_color(style.notify);
    };

    const show_select_car_notify = () => {
        set_select_car_notify("Please select a car");
        set_select_car_notify_color(style.error);
    };

    const hide_set_rules_notify = () => {
        set_set_rules_notify(".");
        set_set_rules_notify_color(style.notify);
    };

    //popup states
    const [is_set_rules_popup_open, set_is_set_rules_popup_open] = useState(false);
    const [time_limit, set_time_limit] = useState(-1);
    const [health_score_reduction, set_health_score_reduction] = useState(0);
    const [rounds, set_rounds] = useState(0);
    const [is_join_lobby_popup_open, set_is_join_lobby_popup_open] = useState(false);
    const [is_lobby_popup_open, set_is_lobby_popup_open] = useState(false);
    const [is_select_car_popup_open, set_is_select_car_popup_open] = useState(false);
    const [gameID, setGameID] = useState("");
    const [games_played, set_games_played] = useState(0);

    useEffect(() => {
        if (waitForLobbyInfo && data.lobby) {
            setWaitForLobbyInfo(false);
            open_lobby_popup();
        }
    }, [data.lobby])

    useEffect(() => {
        getGamesPlayed();
    }, [])

    async function getGamesPlayed() {
        let res = await request("/users/me/gamecount");

        if (res == null){
            return;
        }
        if (res.status === 200) {
            let games_info = await res.json();
            set_games_played(games_info[0].amount);
        }
    }

    //popup functions
    const open_set_rules_popup = () => {
        set_is_set_rules_popup_open(true);
    };

    const close_set_rules_popup = () => {
        set_is_set_rules_popup_open(false);
    };

    const create_lobby = () => {
        if (time_limit === -1) {
            set_set_rules_notify("Please insert round time limit");
            set_set_rules_notify_color(style.error);
            return;
        }
        else if (health_score_reduction === 0) {
            set_set_rules_notify("Please insert health score reduction");
            set_set_rules_notify_color(style.error);
            return;
        }
        else if (rounds === 0) {
            set_set_rules_notify("Please insert rounds");
            set_set_rules_notify_color(style.error);
            return;
        }

        requestCreateLobby(time_limit, health_score_reduction, rounds);
        setWaitForLobbyInfo(true);
        open_lobby_popup();
    };

    const open_join_lobby_popup = () => {
        set_is_join_lobby_popup_open(true);
    };

    const close_join_lobby_popup = () => {
        set_is_join_lobby_popup_open(false);
    };

    const join_lobby = () => {
        setWaitForLobbyInfo(true);
        requestJoinLobby(gameID);
    }

    function onHandleStartGame(ev) {
        let json;
        try {
            json = JSON.parse(ev.data);
        } catch (err) {
            return;
        }
        if (!json.messageType) {
            return console.log("[WS] no message type sent");
        } else {
            let messageType = parseInt(json.messageType);
            switch (messageType) {
                case MessageType.GAME_STARTING:
                    data.ws?.removeEventListener("message", onHandleStartGame);
                    close_lobby_popup();

                    navigate("/game");
                    break;
            }
        }
    }

    const open_lobby_popup = () => {
        set_is_lobby_popup_open(true);
        set_is_select_car_popup_open(false);
        set_is_join_lobby_popup_open(false);
        set_is_set_rules_popup_open(false);

        data.ws?.addEventListener("message", onHandleStartGame);

        setTimeout(() => {
            if (lobby_mode === "create") {
                set_waiting_notify_color(style.notify);
                set_display_start_game_button(style.big_button);
                set_is_start_game_disabled(false);
            } else {
                set_is_start_game_disabled(true);
                set_display_start_game_button(style.hide_big_button);
                set_waiting_notify_color(style.waiting);
            }
        }, 500);

    };

    const close_lobby_popup = () => {
        set_is_lobby_popup_open(false);
    };

    const handle_start_game = () => {
        requestStartGame();
    }

    async function open_select_car_popup() {
        set_is_select_car_popup_open(true);
    };

    const close_select_car_popup = () => {
        set_is_select_car_popup_open(false)
    };

    const handle_open_lobby_popup = () => {
        if (!(Array.from(document.querySelectorAll('input[type="radio"][name="car"]')).some((radio) => (radio as HTMLInputElement).checked))) {
            show_select_car_notify();
        }
        else {
            function onSelectCarMessage(ev) {
                console.log(ev.data);
                let json;
                try {
                    json = JSON.parse(ev.data);
                } catch (err) {
                    if (ev.data.includes("SUCCESS")) {
                        console.log("hii")
                        data.ws?.removeEventListener("message", onSelectCarMessage);
                        if (lobby_mode === "create") {
                            open_set_rules_popup();
                        } else {
                            open_join_lobby_popup();
                        }
                    }
                }
            }

            console.log(data);
            data.ws?.addEventListener("message", onSelectCarMessage);
            sendSelectedCar(selected_car_id);
        }
    };

    const playableCars = data.cars.filter(c => c.status === UserStatus.Online);

    //Define the select car popup
    const select_car_popup_content =
        <div className={popup_style.popup_content}>
            <div id={rules_style.header}>
                <p className={popup_style.back_arrow} onClick={close_select_car_popup}>←</p>
                <h2>Select car</h2>
            </div>

            <div>
                <div onClick={hide_select_car_notify}>
                    {playableCars.length > 0 ? playableCars.map((c) => (
                        <div key={c.id} className={select_car_style.car_component}>
                            <input type="radio" name="car" id={"car" + c.id.toString()} onChange={() => { set_selected_car_id(c.id) }}></input>
                            <label htmlFor={"car" + c.id.toString()}>{c.name} ({c.ownername})</label>
                        </div>
                    )) : <p>No cars online and idle.</p>}

                </div>

                <div><p className={`${select_car_notify_color}`}>{select_car_notify}</p></div>

                <button className={style.big_button} onClick={handle_open_lobby_popup}>Confirm</button>
            </div>

        </div>

    //Define the set rules popup
    const set_rules_popup_content =
        <div className={popup_style.popup_content} id={rules_style.holder}>
            <div className={popup_style.header}>
                <p className={popup_style.back_arrow} onClick={close_set_rules_popup}>←</p>
                <h2>Game Rules</h2>
            </div>

            <div id={rules_style.options_div} onClick={hide_set_rules_notify}>
                <fieldset>
                    <legend>Round Time Limit</legend>
                    <input type="radio" name="round_time_limit" id="15m" onChange={() => { set_time_limit(15) }}></input>
                    <label htmlFor="15m">15m</label>

                    <input type="radio" name="round_time_limit" id="10m" onChange={() => { set_time_limit(10) }}></input>
                    <label htmlFor="10m">10m</label>

                    <input type="radio" name="round_time_limit" id="5m" onChange={() => { set_time_limit(5) }}></input>
                    <label htmlFor="5m">5m</label>

                    <input type="radio" name="round_time_limit" id="no_time_limit" onChange={() => { set_time_limit(0) }}></input>
                    <label htmlFor="no_time_limit">None</label>
                </fieldset>

                <fieldset>
                    <legend>Health score percentage reduction per collision</legend>
                    <input type="radio" name="health_score_reduction" id="20%" onChange={() => { set_health_score_reduction(20) }}></input>
                    <label htmlFor="20%">20%</label>

                    <input type="radio" name="health_score_reduction" id="15%" onChange={() => { set_health_score_reduction(15) }}></input>
                    <label htmlFor="15%">15%</label>

                    <input type="radio" name="health_score_reduction" id="10%" onChange={() => { set_health_score_reduction(10) }}></input>
                    <label htmlFor="10%">10%</label>

                    <input type="radio" name="health_score_reduction" id="5%" onChange={() => { set_health_score_reduction(5) }}></input>
                    <label htmlFor="5%">5%</label>
                </fieldset>

                <fieldset>
                    <legend>Rounds</legend>
                    <input type="radio" name="rounds" id="1" onChange={() => { set_rounds(1) }}></input>
                    <label htmlFor="1">1</label>

                    <input type="radio" name="rounds" id="2" onChange={() => { set_rounds(2) }}></input>
                    <label htmlFor="2">2</label>

                    <input type="radio" name="rounds" id="3" onChange={() => { set_rounds(3) }}></input>
                    <label htmlFor="3">3</label>

                    <input type="radio" name="rounds" id="4" onChange={() => { set_rounds(4) }}></input>
                    <label htmlFor="4">4</label>
                </fieldset>
            </div>

            <div><p className={`${set_rules_notify_color}`}>{set_rules_notify}</p></div>

            <button className={style.big_button} onClick={create_lobby}>Create Lobby</button>
        </div>


    //Define the join lobby popup
    const join_lobby_popup_content =
        <div className={popup_style.popup_content}>
            <div className={popup_style.header}>
                <p className={popup_style.back_arrow} onClick={close_join_lobby_popup}>←</p>
                <h4>Join Lobby</h4>
            </div>

            <form>
                <label htmlFor="game_id">Game ID</label>
                <input type="text" id="game_id" value={gameID} onChange={(e) => setGameID(e.target.value)}></input>
            </form>

            <div><p className={`${set_lobby_notify_color}`}>Invalid Game ID</p></div>

            <button className={style.big_button} onClick={join_lobby}>Join</button>
        </div>

    //Define lobby popup
    const lobby_popup_content =
        <div className={popup_style.popup_content}>
            <h4>Game id</h4>
            <p className={style.num_stats}>{data.lobby?.gameCode}</p>

            <div id={lobby_style.players_list}>
                <ul>
                    <li key="players"><h4>Players</h4></li>
                    {data.lobby?.players.map(p => <li id={p.username === data.username ? lobby_style.current_player : ""} key={p.playerId}>{p.username}</li>)}
                </ul>
            </div>
            <div><p className={`${style.notify} ${waiting_notify_color}`}>Waiting for the game to be started...</p></div>
            <button className={`${display_start_game_button}`} onClick={handle_start_game} disabled={is_start_game_disabled}>Start Game</button>
        </div>

    return <div className={style.page_container}>
        <Popup isOpen={is_set_rules_popup_open} onRequestClose={close_set_rules_popup} content={set_rules_popup_content}></Popup>
        <Popup isOpen={is_join_lobby_popup_open} onRequestClose={close_join_lobby_popup} content={join_lobby_popup_content}></Popup>
        <Popup isOpen={is_lobby_popup_open} onRequestClose={close_lobby_popup} content={lobby_popup_content}></Popup>
        <Popup isOpen={is_select_car_popup_open} onRequestClose={close_select_car_popup} content={select_car_popup_content}></Popup>

        <h1>Welcome back, {data.username}!</h1>
        <div id={home_style.stats_holder}>
            <div className={home_style.stats}>
                <h2>Games played</h2>
                <div><p className={style.num_stats}>{games_played}</p></div>
            </div>
            <div className={home_style.stats}>
                <h2>Friends</h2>
                {data.friends.slice(0, 3).map(f =>
                    <div key={f.id} className={home_style.element_holder}>
                        <p>{f.username}</p>
                        <div className={style.connection_holder}>
                            <p className={f.status === UserStatus.Online ? style.idle_circle : (f.status === UserStatus.Offline ? style.offline_circle : style.online_circle)}>⬤</p>
                            <p>{f.status}</p>
                        </div>
                    </div>
                )}

                <Link to="/friends">→ View all friends...</Link>
            </div>
            <div className={home_style.stats}>
                <h2>Cars</h2>
                {data.cars.slice(0, 3).map(c =>
                    <div key={c.id} className={home_style.element_holder}>
                        <p>{c.name}</p>
                        <div className={style.connection_holder}>
                            <p className={c.status === UserStatus.Online ? style.online_circle : (c.status === UserStatus.Offline ? style.offline_circle : style.idle_circle)}>⬤</p>
                            <p>{c.status}</p>
                        </div>
                    </div>
                )}

                <Link to="/cars">→ View all cars...</Link>
            </div>
        </div>

        <div id={home_style.buttons_holder}>
            <button className={style.big_button} onClick={() => { set_lobby_mode("create"); open_select_car_popup() }}>Create Game</button>
            <button className={style.big_button} onClick={() => { set_lobby_mode("join"); open_select_car_popup() }}>Join Game</button>
        </div>
    </div>
}