#grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}

#game{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #8c8c8c;
    padding: 10px;
    margin-bottom: 30px;
}

#players{
    border-radius: 10px;
    border: 1px solid #8c8c8c;
    padding: 10px;
    grid-area: 1 / 1 / 3 / 2;
}

h5{
    font-size: 20px;
}

#current_player{
    color: #EA1C2D;
}

.health_score{
    display: flex;
    align-items: center;
}

#game_state{
    border-radius: 10px;
    border: 1px solid #8c8c8c;
    padding: 10px;
    grid-area: 1 / 2 / 2 / 3;
}

.state{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#rules{
    border-radius: 10px;
    border: 1px solid #8c8c8c;
    padding: 10px;
    grid-area: 2 / 2 / 3 / 3;
}

.progress_bar_holder {
    display: flex;
    align-items: center;
}

.progress_bar{
    min-width: 200px;
    max-width: 500px;
    height: 30px;
    background-color: #BDBDBD;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 60px;

}

.progress{
    width: 100%; /*initially 100% change it when hit*/
    height: 100%;
    background-color: #54BF00;
    border-radius: 5px;
}