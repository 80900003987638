import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
//@ts-ignore
import * as profile_style from "./css/profile.module.scss";
//@ts-ignore
import * as style from "./css/root.module.css";
import { request } from "./apiRequest";

export default function Password() {
    const [password, set_password] = useState("");
    const [confirm_password, set_confirm_password] = useState("");
    const [notify_message, set_notify_message] = useState(".");
    const [notify_class, set_notify_class] = useState(style.notify);

    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    const token = queryParams.get("token");

    async function reset_password(){
        if (password === ""){
            set_notify_message("Please insert password");
            set_notify_class(style.error);
            return;
        }
        if (confirm_password === ""){
            set_notify_message("Please confirm your password");
            set_notify_class(style.error);
            return;
        }
        if (password !== confirm_password){
            set_notify_message("Passwords do not match!");
            set_notify_class(style.error);
            return;
        }

        let res = await request("/resetpassword/change", {
            method: "post",
            body: JSON.stringify({token: token, password: password}),
            headers: {
                "Content-Type": "application/json"
            }
        })
    }
    
    return <>
        <div className={profile_style.holder}>
            <div>
                <h1>Reset Password</h1>
                <form className={profile_style.form}>
                    <label htmlFor={profile_style.disabled_email}>Email</label>
                    <input type="email" id={profile_style.disabled_email} value={email ? email : ""} disabled></input>

                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" value={password} onChange={(e) => { set_password(e.target.value); set_notify_message("."); set_notify_class(style.notify) }}></input>

                    <label htmlFor="confirm_password">Confirm Password</label>
                    <input type="password" id="confirm_password" value={confirm_password} onChange={(e) => { set_confirm_password(e.target.value); set_notify_message("."); set_notify_class(style.notify) }}></input>


                    <p className={`${notify_class}`}>{notify_message}</p>

                    <div className={profile_style.buttons_holder}>
                        <input className={style.confirm_button} type="button" value="Confirm" onClick={reset_password}></input>
                    </div>
                </form>
            </div>
        </div>
    </>
}