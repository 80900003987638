import { createContext } from "react";
import { Car, User } from "../../types/types";

interface Player {
    playerId: number,
    health: number,
    username: string
}

interface Lobby {
    gameCode: string,
    players: Player[],
    gs_duration: number,
    gs_rounds: number,
    health_red_per: number,
    currentRound: number,
    started: boolean,
    owner: number
}

export interface WebData extends User {
    cars: Car[],
    ws: WebSocket | undefined,
    lobby: Lobby | undefined,
    updateData: (currentData: WebData, newData?: Object | "logout") => Promise<void>
}

export const emptyData = {
    id: 0,
    email: "",
    username: "...",
    friends: [],
    country: "NL",
    is_site_admin: false,
    cars: [],
    ws: undefined,
    lobby: undefined,
    updateData: async (a, b) => {return Promise.resolve()}
}

export const DataContext = createContext<WebData>(emptyData);
