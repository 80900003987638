import React, { useState } from "react";
//@ts-ignore
import * as style from "./css/root.module.css";
import { request } from "./apiRequest";
import { Navigate } from "react-router-dom";

export default function DeleteProfile(){
    const [logged_in, set_logged_in] = useState(true);
    async function delete_account() {
        let res = await request("/users/me", {method: "delete"});

        if (res == null) {
            console.log("Network error.");
            return;
        }        

        if (res.status !== 200) {
            console.log("Something went wrong deleting the profile");
            return;
        }

        set_logged_in(false);
    }

    if (!logged_in){
        return <Navigate to="/login" />;
    }

    return <div className={style.page_container}>
        <h1>Delete Profile</h1>
        <p>
            We respect your decision to have your account deleted, even though we are sad to see you depart. 
            Before moving further, please take a time to go over the following information:
        </p>

        <h2>Important Information</h2>
        <ul>
            <li>The action of deleting your account is irreversible. If you proceed, all your data will be permanently removed from our server.</li>
            <li>If there is any information you want to keep after your account has been deleted, please create a backup.</li>
            <li>Once you proceed you will be logged out and won't be able to log in.</li>
        </ul>

        <h2>Still Want To Delete Your Account?</h2>
        <p>Please click the "Delete Account" button below to remove your account.</p>
        <button className={style.small_button} onClick={delete_account}>Delete Account</button>

        <h2>Do You Have Questions For Us?</h2>
        <p>You can reach out to our support team at anytime by writing an email: <a id={style.email_link} href="mailto:admin@hotpies.cool">admin@hotpies.cool</a> before proceeding if you have questions.</p>

        <h2>Feedback</h2>
        <p>
            Your feedback is really valuable to us, to make our product safer and more enjoyable to all of our users and community! If you are departing because of a problem 
            or worry, kindly share your thoughts with us (sending an email to <a id={style.email_link} href="mailto:admin@hotpies.cool">admin@hotpies.cool</a>) so we can know how we can do better.
        </p>

        <h3>Thank you for being a part of our community. We hope to see you again in the future!</h3>
    </div>
}