import React, { useContext, useEffect, useState } from "react";
//@ts-ignore
import * as table_style from "./css/table.module.css";
//@ts-ignore
import * as style from "./css/root.module.css";
//@ts-ignore
import * as popup_style from "./css/popup.module.css";
//@ts-ignore
import Popup from "./PopupInterface.tsx";
import { request } from "./apiRequest";
import { Friend } from "../../types/types";
import UserStatus from "../../types/UserStatus.js";
import { DataContext } from "./DataContext.ts";

export default function Friends() {
    const data = useContext(DataContext);
    // const [friends, set_friends] = useState([]);
    const [search_friends, set_search_friends] = useState("");
    const [friends_suggestion, set_friends_suggestion] = useState([]);
    const [search_suggested_friends, set_search_suggested_friends] = useState("");

    //add friends popup
    const [is_add_new_friends_popup_open, set_is_add_new_friends_popup_open] = useState(false);
    const [add_friends_error_message, set_add_friends_error_message] = useState(".");
    const [add_friends_error_style, set_add_friends_error_style] = useState()

    async function get_friends_suggestion() {
        console.log("suggesting new friends!")
        let res = await request("/users/newFriends");

        if (res == null){
            set_add_friends_error_message("Network error. Try again later");
            set_add_friends_error_style(style.error);
            return;
        }
        if(res.status === 400){
            set_add_friends_error_message("Wrong input");
            set_add_friends_error_style(style.error);
            return;
        }
        if(res.status === 404){
            set_add_friends_error_message("Error 404. Not found");
            set_add_friends_error_style(style.error);
            return;
        }

        set_friends_suggestion(await res.json());
    }

    const open_add_new_friends_popup = () => {
        get_friends_suggestion();
        set_is_add_new_friends_popup_open(true);
    };

    const close_add_new_friends_popup = () => {
        set_is_add_new_friends_popup_open(false);
    };

    async function add_friend(user_id) {
        let res = await request("/users/user/addFriend/" + user_id, {method: "put"});

        if (res == null){
            set_add_friends_error_message("Network error. Try again later");
            set_add_friends_error_style(style.error);
            return;
        }
        if(res.status === 400){
            set_add_friends_error_message("Wrong input");
            set_add_friends_error_style(style.error);
            return;
        }
        if(res.status === 404){
            set_add_friends_error_message("Error 404. Not found");
            set_add_friends_error_style(style.error);
            return;
        }
        get_friends_suggestion();
    }

    const add_new_friends_popup_content =
        <div className={popup_style.popup_content}>
            <h1>Add new friends</h1>
            <input type="text" placeholder="Search by username" value={search_suggested_friends} onChange={e => {set_search_suggested_friends(e.target.value)}}></input>

            <table>
                <thead>
                    <tr>
                        <td>Username</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {friends_suggestion
                    .filter((user: Friend) => (user.username.toLowerCase().includes(search_suggested_friends.toLowerCase()) || search_suggested_friends === ""))
                    .map((user: Friend) => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td><button className={style.small_button} onClick={()=>{add_friend(user.id)}}>Add</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <p></p>
            <button id={popup_style.large_confirm} className={style.small_button} onClick={close_add_new_friends_popup}>Confirm</button>
        </div>

    return <div className={style.page_container}>
        <div className={style.page_header_holder}>
            <h1>Your friends</h1>
            <button className={style.big_button} onClick={open_add_new_friends_popup}>Add Friend</button>
        </div>

        <input type="text" placeholder="Search username or connection" value={search_friends} onChange={e => {set_search_friends(e.target.value)}}></input>
        <Popup isOpen={is_add_new_friends_popup_open} onRequestClose={close_add_new_friends_popup} content={add_new_friends_popup_content}></Popup>
        
        <table>
            <thead>
                <tr>
                    <td>Username</td>
                    <td>Connection</td>
                </tr>
            </thead>
            <tbody>
                {data.friends
                .filter(f => (f.username.toLowerCase().includes(search_friends.toLowerCase()) || f.status.toLowerCase().includes(search_friends.toLowerCase()) || search_friends === ""))
                .map(f => (
                    <tr key={f.id}>
                        <td>{f.username}</td>
                        <td>
                            <div className={style.connection_holder}>
                                <p className={f.status === UserStatus.Online ? style.idle_circle : (f.status === UserStatus.Offline ? style.offline_circle : style.online_circle)}>⬤</p>
                                <p>{f.status}</p>
                            </div>
                        </td>
                    </tr>
                ))
                }
            </tbody>
        </table>
    </div>
}