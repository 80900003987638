#players_list{
    border: 1px solid #8c8c8c;
    padding-left: 10px;
    border-radius: 10px;
    margin-top: 30px;
}

#players_list > ul {
    list-style-type: none;
}

#wait {
    color: transparent;
    font-size: 13px;
}

#button {
    background-color: transparent;
    color: transparent;
}

#current_player {
    color: #EA1C2D;
}