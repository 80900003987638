table{
    padding-top: 30px;
    width: 100%;
    overflow-y: scroll;
    table-layout: fixed;
}
thead > tr > td{
    color: #F8DE08;
}
label{
    margin-right: 10px;
}
input[type=text], select{
    border-radius: 10px;
    padding-left: 10px;
}
input[type=text]{
    width: 400px;
}
#ranking_settings{
    padding-top: 15px;
}